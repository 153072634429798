


const thewisher1 = {
  global: {
    colors: {
      /* BEGIN: Color Palette Definition */
      ruby: {
        dark: "#7D2F36",
        light: "#C74C56"
      },
      "ruby!": "#A63F48",
      eggplant: {
        dark: "#170E17",
        light: "#593659"
      },
      "eggplant!": "#261726",
      flesh: {
        dark: "#9C8068",
        light: "#FFD2AB"
      },
      "flesh!": "#D9B391",
      aqua: {
        dark: "#3BD1B3",
        light: "#47FFDA"
      },
      "aqua!": "#44F2D0",
      "grey-1": "#f9f9f9",
      "grey-2": "#ECE9E3",
      "grey-3": "#737069",
      "grey-4": "eggplant!",
      "grey-4-bak": "#52504C",
      "accent-1": "aqua!",
      /* END: Color Palette Definition */
      /* BEGIN: Mapping Colors to Grommet Namespaces */
      background: {
        dark: "grey-4",
        light: "grey-1"
      },
      "background-back": {
        dark: "grey-4",
        light: "grey-1"
      },
      "background-front": {
        dark: "grey-3",
        light: "grey-2"
      },
      brand: "ruby!",
      control: {
        dark: 'accent-1',
        light: 'brand',
      },
      input: {
        background: "blue"
      },
      text: {
        dark: "grey-1",
        light: "grey-4"
      }
    },
    focus: {
      border: {
        color: "aqua"
      }
    }
    /* END: Mapping Colors to Grommet Namespaces */
    /* BEGIN: Mapping Colors to Components */
  },

    heading:{
      color:"text"
    },
    page:{
      color:{
        dark: "flesh"
      }
    },
    pageHeader:{
      title:{
        color:{
          dark:"flesh!"
        }
      }
    }, 
    text:{
      font:{
        family:"", 
      }, 
    }, 
    card:{
      body:{
        background:{
          light:"grey-1"
        },
      },
      header:{
        background:"grey-2",
        color: "text!"
      }, 
    }, 
    menu:{
      padding: '0px',
      pad: "none",
      item:{
        align: 'center', 
        pad: "none", 
        padding: "0px", 
        margin: "none",
        box: {
          align: 'center', 
          pad: "none", 
          padding: "0px"
        }, 
      group:{
        container:{
          pad: "none"
        }
      }
      }
    }, 
    box:{
      color: "text!"
    }
    /* END: Mapping Colors to Components */
}




export const thewisher = {
  global: {
      colors: {
        /* BEGIN: Color Palette Definition */
        ultramarine: {
          dark: "#3D6373",
          light: "#C4EEF2"
        },
        "ultramarine!": "#3D6373",
        eggplant: {
          dark: "#170E17",
          light: "#593659"
        },
        "eggplant!": "#011826",
        "blue!": "#033E8C",
        "orange!": "#C7614D",

        "grey-1": "#f9f9f9",
        "grey-2": "#E0E0E0",
        "grey-3": "#315359",
        "grey-4": "#17282B",

        "accent-1": "orange!",
        /* END: Color Palette Definition */
        /* BEGIN: Mapping Colors to Grommet Namespaces */
        
        background: {
          dark: "grey-4",
          light: "grey-1"
        },

        brand: "ultramarine!",

        control: {
          dark: 'accent-1',
          light: 'accent-1',
          color :{
            dark: 'accent-1',
            light: 'accent-1',
          }
        },
        input: {
          background: "accent-1"
        },
        text: {
          dark: "grey-1",
          light: "grey-4"
        }
      },
      focus: {
        border: {
          color: "ultramarine"
        }
      }
      /* END: Mapping Colors to Grommet Namespaces */
      /* BEGIN: Mapping Colors to Components */
    },
    heading:{
      color:"text"
    },
    page:{
      color:{
        dark: "text"
      }
    },
    box:{
      color:{
        dark: "text"
      }
    },
    pageHeader:{
      title:{
        color:{
          dark:"text"
        }
      }
    }, 
    a:{
      color: "text"
    }, 
    anchor:{
      hover:{
        textDecoration: "none"
      }
    },
    text:{
      font:{
        family:"", 
      },
      color: "text"
    }, 
    card:{
      body:{
        background:{
          light:"grey-1"
        },
        text: {
          color:"text"
        }
      },
      header:{
        //background:"grey-1",
        text: {
          color:"text"
        },
        background:{
          light:"grey-2", 
          dark:"grey-3"
        },
      },
    }, 
    menu:{
      padding: '0px',
      pad: "none",
      item:{
        align: 'center', 
        pad: "none", 
        padding: "0px", 
        margin: "none",
        box: {
          align: 'center', 
          pad: "none", 
          padding: "0px"
        }, 
      group:{
        container:{
          pad: "none"
        }
      }
      }
    }
    /* END: Mapping Colors to Components */
}