import React from 'react'
import {Anchor, Box, Button, Card, CardBody, CardFooter, CardHeader, CheckBox, Footer, Header, Heading, Image, Layer, Paragraph, Stack, Tag, Text} from 'grommet';
import {Edit,Link} from "grommet-icons";
import StarRating from './components/StarRating';
import WishForm from './WishForm';

export default function Wish3(props){
    const [isLoading, setIsLoading] = React.useState();
    const [isEdit, setIsEdit] = React.useState();
    const [purchased, setPurchased] = React.useState(props.isPurchased);
    const date = new Date(props.createdAt);
    const ref = React.useRef();

    function togglePurchased(){
        props.putwish(
            {
                "isPurchased": !purchased
            },props.id)
        setPurchased(!purchased);
    }

    function toggleEdit(){
        setIsEdit(!isEdit);
    }

    return (
        <Stack>
            <Box height={{min: 'small', max: 'large'}} flex={{ grow: 2, shrink: 2}} elevation="small" gap="small" direction='row-responsive' border={{ color: 'grey-2', size: 'xsmall' }}>      
                {props.img && 
                    <Box flex={{ grow: 0, shrink: 2}} >
                        <Image height={{min: 'small', max: 'medium'}} fill="vertical" elevation="medium" fit="cover" src={props.img}/>
                    </Box>} 
                    <Box justify="start" direction = "column" flex={{ grow: 1, shrink: 1 }} pad="medium"> 
                        <Box>
                            <Heading size="small">{props.name}</Heading>                                                         
                        </Box> 
                        <Box flex={{ grow: 0, shrink: 0}} direction = "row-responsive" wrap="true">
                            <Box flex={{ grow: 10, shrink: 0 }}><StarRating grade={props.importance}/></Box>
                            <Box flex={{ grow: 0, shrink: 0 }}><Tag width="185px" height="30px" size="xsmall" name="Ajouté le" value={new Intl.DateTimeFormat('fr-fr').format(date)}/></Box>
                            {props.isSecret && 
                                <Box flex={{ grow: 0, shrink: 1 }}><Tag width="100px" height="30px" size="xsmall" value={<> <span role="img" aria-label="secret">👻</span>secret </>}/></Box>
                            }
                        </Box>
                        <Box>
                            <Box overflow={"scroll"}>
                                <Paragraph >{props.comment}</Paragraph>
                            </Box>
                        </Box>
                        
                            
                        <Box direction="row-responsive" alignSelf="end"> 
                            <Box align="center" direction="row" wrap="true" gap="small">
                                {props.url && 
                                <Button secondary>
                                    <Link size="small"/> <Anchor href={props.url} label="Voir le site" rel="noopener noreferrer" target="_blank"/> 
                                </Button>
                                }   
                                <Box>
                                    {props.self===false &&
                                    <CheckBox
                                        toggle
                                        checked={purchased}
                                        onChange={()=>togglePurchased()}
                                        label="Acheté"
                                    />
                                    }
                                </Box>
                                <Box>
                                    {(props.isSecret || props.self) && 
                                        <Button  
                                            secondary
                                            onClick={()=>setIsEdit(!isEdit)}
                                            label="Modifier"
                                        />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                {isEdit && 
                <Layer>
                    <WishForm 
                        editForm 
                        deletewish={props.deletewish} 
                        putwish={props.putwish} 
                        getWishDetails={props.getWishDetails} 
                        toggle={toggleEdit} 
                        wish={props} 
                        open={isEdit}>
                    </WishForm>
                </Layer>
                }
            </Box>
            {(props.self===false && props.isPurchased) &&
                <Box background = "#000000A0" fill="true" elevation="small" pad="medium" direction='column' border={{ color: 'grey-2', size: 'xsmall' }}>
                    <Heading alignSelf='center' textAlign='center'>Cadeau acheté ! 🎉</Heading>
                    <CheckBox
                                    toggle
                                    checked={purchased}
                                    onChange={()=>togglePurchased()}
                                    label="Acheté"
                                />
                </Box>
            }
        </Stack>
    );
}
