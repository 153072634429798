import React from 'react'
import { useState } from 'react';
import {Box, Button, Form, Heading, Main, Layer, Page, PageContent, PageHeader, Paragraph, FormField, TextInput} from 'grommet'
import { Hide, View } from 'grommet-icons';
import userservice from './services/userservice'
import { useLoaderData, Link } from "react-router-dom";
import LoginBg from './login-bg2.svg'


export async function loader({ params }) {
    return (params);
}

function ChangePassword(props){
    const [value, setValue] = React.useState({});
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('typing');
    const [reveal, setReveal] = React.useState(false);
    
    const {userId,hash} = useLoaderData();

    const validate=(field,form)=>{
        if (field!==form.pass){
            return "les mots de passe doivent être identiques"
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        setStatus('submitting');
        try {
          await submitForm(value.pass);
          setStatus('success');
        } catch (err) {
          setStatus('typing');
          setError(err);
        }
    }

    function submitForm(pass){
        return userservice.changePasswordFromHash(userId,hash,pass)
        .then(response => {
            if(!response.ok){
                throw Error("Le lien de récupération n'était pas valide");
            }
            return response
        })
        .catch((err)=>{
            throw Error(err.message);
        })
    }
    
    return (
        <Layer full animation="fadeIn" background={{ image: `url(${LoginBg})`, color: 'light-2' }}>
            <Box fill="vertical" justify="center" align="center">
                <Page
                    pad="large"
                    background={{ color: "white", opacity: 0.9 }}
                    width="medium"
                    justify="center"
                    align="center"
                >
                    <PageHeader title="Votre nouveau mot de passe" />
                    <PageContent>
                        <Box>
                            {status !== "success" && error == null && (
                                <Form
                                    validate="submit"
                                    value={value}
                                    onChange={nextValue => setValue(nextValue)}
                                    onSubmit={handleSubmit}
                                >
                                    {/* New Password Field */}
                                    <FormField name="pass" htmlFor="text-input-pass" label="Mot de passe">
                                        <Box
                                            width="medium"
                                            direction="row"
                                            margin={{ vertical: "small" }}
                                            align="center"
                                            round="small"
                                        >
                                            <TextInput
                                                id="text-input-pass"
                                                name="pass"
                                                type={reveal ? 'text' : 'password'}
                                            />
                                            <Button
                                                icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                                onClick={() => setReveal(!reveal)}
                                            />
                                        </Box>
                                    </FormField>

                                    {/* Confirm Password Field */}
                                    <FormField name="passconfirm" htmlFor="text-input-passconfirm" label="Confirmer le mot de passe" validate={validate}>
                                        <Box
                                            width="medium"
                                            direction="row"
                                            margin={{ vertical: "small" }}
                                            align="center"
                                            round="small"
                                        >
                                            <TextInput
                                                id="text-input-passconfirm"
                                                name="passconfirm"
                                                type={reveal ? 'text' : 'password'}
                                            />
                                            <Button
                                                icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                                onClick={() => setReveal(!reveal)}
                                            />
                                        </Box>
                                    </FormField>

                                    {/* Submit Button */}
                                    <Box direction="row" gap="medium" pad={{ vertical: "medium" }}>
                                        <Button type="submit" primary label="Confirmer" />
                                    </Box>
                                </Form>
                            )}

                            {/* Success Message */}
                            {status === "success" && (
                                <Box>
                                    <Paragraph>Votre mot de passe a bien été modifié.</Paragraph>
                                    <Link to="/">Retour à la connexion</Link>
                                </Box>
                            )}

                            {/* Error Message */}
                            {error !== null && (
                                <Box>
                                    <Paragraph>Erreur : {error.message}</Paragraph>
                                    <Link to="/">Retour à la connexion</Link>
                                </Box>
                            )}
                        </Box>
                    </PageContent>
                </Page>
            </Box>
        </Layer>
    );
}

export default ChangePassword