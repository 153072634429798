import React from 'react'
import { useState } from 'react';
import {Box, Button, Form, Heading, Layer, Main, Page, PageContent, PageHeader, Paragraph, FormField, TextInput} from 'grommet'
import userservice from './services/userservice'
import LoginBg from './login-bg2.svg'

function ForgotPassword(props){
    const [value, setValue] = React.useState({});
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('typing');


    const validate= (email,form)=>{
        if (email.includes("@")==false){
            return "Veuillez renseigner une adresse email"
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setStatus('submitting');
        try {
          await submitForm(value.mail);
          setStatus('success');
        } catch (err) {
          setStatus('typing');
          setError(err);
          console.log(err)
        }
    }

    function submitForm(email) {
        return userservice.sendResetPassword(email)
        .then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
    }

    return (
        <Layer full animation="fadeIn" background={{ image: `url(${LoginBg})`, color: 'light-2' }}>
            <Box fill="vertical" justify="center" align="center">
                <Page
                    pad="large"
                    background={{ color: "white", opacity: 0.9 }}
                    width="medium"  // Ensures a responsive width
                    justify="center"
                    align="center"
                >
                    <PageHeader title="Mot de passe oublié" />
                    <PageContent>
                        {status !== "success" && (
                            <Paragraph>
                                Nous allons vous envoyer un code de récupération.
                            </Paragraph>
                        )}

                        <Box>
                            {status !== "success" && (
                                <Form
                                    validate="submit"
                                    value={value}
                                    onChange={nextValue => setValue(nextValue)}
                                    onReset={() => setValue({})}
                                    onSubmit={handleSubmit}
                                >
                                    <FormField
                                        name="mail"
                                        htmlFor="text-input-id"
                                        label="Adresse email"
                                        validate={validate}
                                    >
                                        <TextInput id="text-input-id" name="mail" />
                                    </FormField>

                                    <Box direction="row" gap="medium" pad={{ vertical: "medium" }}>
                                        <Button type="submit" primary label="Envoyer un code de récupération" />
                                    </Box>
                                </Form>
                            )}

                            {status === "success" && (
                                <Box>
                                    <Paragraph>
                                        Merci ! Si l'utilisateur existe, un email a été envoyé dans sa boîte mail.
                                    </Paragraph>
                                    <Paragraph>
                                        Vous pouvez fermer cette fenêtre et consulter votre boîte mail.
                                    </Paragraph>
                                </Box>
                            )}
                        </Box>
                    </PageContent>
                </Page>
            </Box>
        </Layer>
    );
};

export default ForgotPassword