import React from "react";
import { Box, Heading, Paragraph, Text } from "grommet";

const Placeholder = () => (
  <Box pad="medium" fill="vertical" justify="center" align="center">
        <Heading level="3">La liste est vide <span role="img" aria-label="cadeau">🎁</span></Heading>
        <Paragraph>...mais tu peux y ajouter un cadeau!</Paragraph>
  </Box>
);

export default Placeholder;
