import React from 'react';
import Main from './Main';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import {Gift, Logout, Menu as MenuIcon, Moon, Sun} from 'grommet-icons'
import { BrowserRouter as Router, Outlet, Route, Routes, Link, redirect } from 'react-router-dom';
import { Anchor, AnchorExtendedProps, Box, Button, Header, Menu,  Nav, PageContent, ResponsiveContext, Text} from 'grommet';
import styled from 'styled-components';
import userservice from './services/userservice';

export default function Home({switchTheme, darkMode, updateUser}) {
  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: 'pink', 
    "&:hover" : {
      color: 'yellow', 
    }     
  };

  const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    margin: 1rem;
    position: relative;
    &:hover {
    color: white;
    text-decoration: none;
    }
    &:focus {
      box-shadox: unset;
    }
  `;

  const StyledLinkForMenu = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
    text-decoration: none;
    }
  `;
  
  const links = [
    <Anchor
      as= {StyledLink}
      to={`my-list`} label="Ma liste"/>,

    <Anchor
      as={StyledLink}
      to={`families`} label="Familles"/>,

    <StyledLinkForMenu to={`my-list`}><Box width="100%" pad="large">Ma liste</Box></StyledLinkForMenu>,
    <StyledLinkForMenu to={`families`}><Box width="100%" pad="large">Familles</Box></StyledLinkForMenu>

  ];


    
  return (
    <ResponsiveContext.Consumer>
      {size=>(
        <PageContent>
          <Header pad="medium" background="brand" sticky="scrollup">
          <Box direction="row" align="center" gap="small">
            <Button icon={<Link to={`my-list`}><Gift/></Link>} hoverIndicator />
          </Box>
          
              {size=="small" ?(
                <Menu pad="none" alignSelf='stretch'
                  icon={<MenuIcon/>}
                  items={[
                    {
                      label: <Box width="100%" pad="none" padding="0px">{links[2]}</Box>                   
                    },
                    {
                      label: <Box width="100%" pad="none" padding="0px">{links[3]}</Box>                   
                    },
                    {
                      icon: (<Box margin= "none" pad="large" align="center">{darkMode?<Sun/>:<Moon/>}</Box>),
                      onClick:()=>{switchTheme()},
                    },
                    {
                      label:"Se déconnecter", 
                      icon: (<Logout/>),
                      onClick:()=>{userservice.signout();updateUser("")},
                    }
                  ]}
                />
              ):
              (
                <Nav direction="row">
                  <Button>{links[0]}</Button>
                  <Button>{links[1]}</Button>
                  <Button 
                    onClick={switchTheme}
                    icon={darkMode?<Sun/>:<Moon/>}
                  />
                  <Button 
                    onClick={()=>{userservice.signout();updateUser("")}}
                    icon={<Logout/>}
                  />
                </Nav>
              )
              }
          </Header>
          <Main/>
        </PageContent>
      )}
    </ResponsiveContext.Consumer>
  );
}